#footer-bar {
  bottom: 1px !important
}




@media (min-width: 500px) {
  #footer-bar {
      width: 490px !important;
      margin: auto;
  }
}


  #footer-bar span {
    font-size: 12px !important;
  }