.Perfil  {
    padding-bottom: 5px !important;
    top: 80px;
}

.Perfil .card-style {
    border-radius: 5px;
    margin: 3px;
    padding:5px;
}

.pull-left {
    float: left !important;
}

.endereco_bar {
    font-size: 18px !important;
    padding-bottom: 15px !important;
}

label {
    padding-right: 15px;
    text-indent: 0px;
}


label input[type="radio"] {
    width: 18px !important;
    height: 18px !important;
    margin-right: 0px !important;
    vertical-align: bottom;
    position: relative;
    top: -5px;
    *overflow: hidden;
}


label input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
    margin-left: 0px !important;
    vertical-align: bottom;
    position: relative;
    top: -5px;
    *overflow: hidden;
}


.hr {
    margin: 5px 0 5px 0 !important;
    padding: 0  !important;
}

.servicos_info_eq div {
    width: 50% !important;
    float: left;
}

.clear {
    clear: both;
}

.topbar {
    background-color: #FAFAFA;
    height: 68px !important;
}


.shadow-m{
    background-color: #FFFFFF;
}