.Orcamento  {
    top: 30px !important;
}

.hide {
    display: none !important;
}

.clititle {
    margin: 15px auto;
}

.limit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Orcamento .btn-full {
    /*width: 100%;*/
}

.Orcamento .form-label-always-active {
    background-color: #FFFFFF !important;
}