.AvaliacoesContent  {
    position: relative;
    top: 80px !important;
}

.AvaliacoesContent .card-bottom {
    padding: 0 7px 15px 7px;
}


.AvaliacoesContent .stars {
    font-size: 25px;
    padding: 10px 17px 15px 17px;
}

.AvaliacoesContent .stars i {
    padding: 3px;
}

.AvaliacoesContent .star_red {
    color:#e9573f;
}


.AvaliacoesContent .star_green {
    color:#8cc152;
}


.AvaliacoesContent .star_yellow {
    color:#f6bb42;
}