.splide__pagination {
    display: none;
}

.splide__slide {
    width: 320px !important;
}

.splide {
    padding-left:15px;
}

.splide__arrows {
    display: none;
}

.splide_top .card {
    height:200px; 
    width: 320px;
}


.card-top {
    top: 130px  !important;
}