.MenuTitle {
    position: fixed;
    width: 100%;
    
    z-index: 15;
    background-color: #f4f4f4;
    height: 64px;
}


@media (min-width: 500px) {
    .MenuTitle {
        width: 500px;
        margin: 0 auto !important;
    }
  }