.PlanosLista  {
    position: relative;
    padding: 12px;
    top: 68px;
}

.PlanosLista .accordion-item {
    background-color: #FFFFFF;
}

.PlanosLista .risk {
    text-decoration: line-through !important;
    color:#CCCCCC !important;
}

.PlanosLista .hide {
    display: none;
}