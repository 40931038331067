.Compartilhar {
    padding: 5px !important;
    top: 80px;
}


.Compartilhar .btn-full {
    width: 100%;
}

.Compartilhar .page-content {
    padding:20px 10px 10px 10px;
}