.splide_names .card {
    width: 100px;
    height: 70px;
}

.splide_names .splide__slide {
    width: 120px !important;
}

.splide_names h6 {
    /*margin: auto 10%;*/
    width: 100px !important;
}