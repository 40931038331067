.Pagamento {
    position: relative;
    top: 80px !important;
}

.Pagamento .form-label-always-active {
    background-color: hsl(0, 0%, 100%) !important;
    left: 15px  !important;
}

.Pagamento .col-8 {
   padding-right:5px !important;
}

.Pagamento .btn-full {
    width: 100%;
}

.hide {
    display: none;
}


.Pagamento .risk {
    text-decoration: line-through !important;
    color: #AAAAAA !important;
}
