.Login .form-custom input, .Login .form-custom input::placeholder {
    font-size: 16px !important;
}


.Login label {
    padding-right: 15px;
    text-indent: 0px;
}


.Login label input[type="checkbox"] {
    width: 23px !important;
    height: 23px !important;
    margin-left: 0px !important;
    vertical-align: bottom;
    position: relative;
    top: -1px;
    *overflow: hidden;
}