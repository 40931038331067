
.Sidebar {
    width: 100vw !important;
}

@media (min-width: 500px) {
    .Sidebar {
        width: 500px !important;
        /*height: 100vh  !important;*/
        margin: 0 auto;
    }
  }

.Sidebar #menu-sidebar {
    height: 100vh;
    width: 280px;
    display: block;
    transform: translateX(-280px);
}


.menu_in {
    transform: translateX(0);
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    animation-fill-mode: forwards;
  }

  .menu_out {
    transform: translateX(-280px);
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s; /* Firefox < 16 */
    -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
    animation: fadeout 1s;
    animation-fill-mode: forwards;
  }


@keyframes fadein {
    from {
        transform: translateX(-280px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes fadeout {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-280px);
    }
}